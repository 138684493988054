import React, { useEffect, useRef, useState } from 'react';
import { TextFieldFormsy, SelectFormsy } from '@fuse';
import Formsy from 'formsy-react';
import * as authActions from 'app/auth/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import jwtService from 'app/services/jwtService/jwtService';
import ControlService from 'app/services/ControlService/ControlService';
import axios from 'axios';
import qs from 'qs';
import aes256 from 'aes256';
import {
    Card, Grid, MenuItem, Paper, CardContent, Typography, Tabs, Tab, Button, InputAdornment,
    Icon
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import * as Actions from 'app/store/actions';
import { setUserData } from '../../../auth/store/actions/user.actions';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

const useStyles = makeStyles(theme => ({
    root: {
        background: 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + darken(theme.palette.primary.dark, 0.5) + ' 100%)',
        color: theme.palette.primary.contrastText
    },
    dialogPaper: {

        width: '400px'
    }
}));

function JWTLoginTab(props) {
    const dispatch = useDispatch();
    const login = useSelector(({ auth }) => auth.login);
    const classes = useStyles();
    const dispatchMsg = useDispatch();
    const [isFormValid, setIsFormValid] = useState(false);
    const formRef = useRef(null);
    const [type_auth, handleTypeAuth] = useState();
    const [open, setOpen] = useState(false);
    const [email, handleEmail] = useState();
    const [perfiles, handlePerfiles] = useState([]);
    const [perfil, handlePerfil] = useState();


    function message(type = "null", message = "") {
        dispatchMsg(
            Actions.showMessage({
                message: message,
                autoHideDuration: 6000, //ms
                anchorOrigin: {
                    vertical: "top", //top bottom
                    horizontal: "center" //left center right
                },
                variant: type //success error info warning null
            })
        );
    }

    useEffect(() => {
        if (login.error && (login.error.email || login.error.password)) {
            formRef.current.updateInputsWithError({
                ...login.error
            });
            disableButton();
        }
    }, [login.error]);

    function disableButton() {
        setIsFormValid(false);
    }

    function enableButton() {
        setIsFormValid(true);
    }

    async function handleCancelClose() {
        setOpen(false);
    }

    function handleSubmitLogin() {
        dispatch(authActions.submitEmail(email, perfil));
    }

    function handleSubmit(model) {
        jwtService.signInWithEmailAndPassword(model.email, model.password)
            .then((response) => {
                console.log(response.data.perfiles)
                handleEmail(response.data.perfiles[0].email)
                if (response.data.perfiles.length > 1) {
                    handlePerfiles(response.data.perfiles)
                    setOpen(true);
                } else {
                    dispatch(authActions.submitEmail(response.data.perfiles[0].email, response.data.perfiles[0].profile_slug));
                }

            }).catch(error => {
                return dispatch({
                    type: LOGIN_ERROR,
                    payload: error
                });
            });
    }

    return (
        <div className="w-full">
            <Formsy
                onValidSubmit={handleSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                ref={formRef}
                className="flex flex-col justify-center w-full"
            >
                <TextFieldFormsy
                    className="mb-16"
                    type="text"
                    name="email"
                    label={type_auth === 'WAD' ? 'Usuario' : 'Correo'}
                    id="email"
                    // validations={{
                    //     minLength: 4
                    // }}
                    // validationErrors={{
                    //     minLength: 'Min character length is 4'
                    // }}
                    onKeyPress={window.clearInterval()}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">email</Icon></InputAdornment>
                    }}
                    variant="outlined"
                    required
                />

                <TextFieldFormsy
                    className="mb-16"
                    type="password"
                    name="password"
                    label="Contraseña"
                    id="password"
                    // validations={{
                    //     minLength: 4
                    // }}
                    // validationErrors={{
                    //     minLength: 'Min character length is 4'
                    // }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Icon className="text-20" color="action">vpn_key</Icon></InputAdornment>
                    }}
                    variant="outlined"
                    required
                />

                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="w-full mx-auto mt-16 normal-case"
                    aria-label="LOG IN"
                    disabled={!isFormValid}
                    value="legacy"
                >
                    Iniciar Sesión
                </Button>

            </Formsy>

            <Formsy className="flex flex-col justify-center w-full">
                <Dialog maxWidth="md" open={open} classes={{ paper: classes.dialogPaper }} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Seleccione el Perfil</DialogTitle>
                    <DialogContent>
                        <Grid item xs={12} md={12} className="alignRight">
                            <SelectFormsy
                                className="mb-16 fullWidthSelect"
                                name="perfil"
                                value=""
                                onChange={e => handlePerfil(e.target.value)}
                                validationError="Seleccione un perfil"
                                required
                                fullWidth>
                                {perfiles.map(item =>
                                    <MenuItem key={item.profile_slug} value={item.profile_slug}>{item.profile_name}</MenuItem>
                                )}
                            </SelectFormsy>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={handleSubmitLogin} color="primary">
                            Continuar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Formsy>
        </div>
    );
}

export default JWTLoginTab;
